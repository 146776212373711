class AlertIntervalEnum {
  static DAILY = 'daily';
  static WEEKLY = 'weekly';
  static BIWEEKLY = 'biweekly';
  static MONTHLY = 'monthly';

  static labelKey(item: string) {
    return AlertIntervalEnum.meta(item)?.labelKey;
  }

  static meta(item: string) {
    switch (item) {
      case AlertIntervalEnum.DAILY:
        return {
          labelKey: 'global.frequencies.daily',
        };
      case AlertIntervalEnum.WEEKLY:
        return {
          labelKey: 'global.frequencies.weekly',
        };
      case AlertIntervalEnum.BIWEEKLY:
        return {
          labelKey: 'global.frequencies.biweekly',
        };
      case AlertIntervalEnum.MONTHLY:
        return {
          labelKey: 'global.frequencies.monthly',
        };
    }
  }

  static all() {
    return [
      {
        key: AlertIntervalEnum.DAILY,
        labelKey: AlertIntervalEnum.labelKey(AlertIntervalEnum.DAILY),
      },
      {
        key: AlertIntervalEnum.WEEKLY,
        labelKey: AlertIntervalEnum.labelKey(AlertIntervalEnum.WEEKLY),
      },
      {
        key: AlertIntervalEnum.BIWEEKLY,
        labelKey: AlertIntervalEnum.labelKey(AlertIntervalEnum.BIWEEKLY),
      },
      {
        key: AlertIntervalEnum.MONTHLY,
        labelKey: AlertIntervalEnum.labelKey(AlertIntervalEnum.MONTHLY),
      },
    ];
  }
}

export default AlertIntervalEnum;
