<template>
  <alerts-index :organization="organization" :fields="fields" :filters="filters" />
</template>

<script>
import Vue from 'vue';
import AlertsIndex from '~/pages/shared/alerts/AlertsIndex';

export default Vue.extend({
  components: {
    AlertsIndex,
  },

  middleware: 'admin',

  props: {
    organization: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    filters: [],

    fields: [
      'label',
      'organization',
      'occupations',
      'employees',
      'graduation_types',
      'interval',
      'created_at',
      'actions',
    ],
  }),
});
</script>
