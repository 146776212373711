<template>
  <t-page>
    <template #header>
      <div class="heading">
        <div>
          <h3 slot="header">
            {{ $t('pages.alert.heading') }}
          </h3>
          <p v-if="showTagline">
            {{ $t('pages.alert.lead') }}
          </p>
        </div>
        <NuxtLink v-if="canCreate" :to="{ name: $RouteEnum.ALERTS.CREATE }">
          <t-button>{{ $t('pages.alert.actions.create') }}</t-button>
        </NuxtLink>
      </div>
    </template>
    <t-grid>
      <t-grid-cell>
        <index-table :query="query" :fields="fields" :filters="filters" />
      </t-grid-cell>
    </t-grid>
  </t-page>
</template>

<script>
import Vue from 'vue';
import UserTypeEnum from '~/enums/UserTypeEnum';
import Alert from '~/models/Alert';
import IndexTable from '~/pages/shared/alerts/partials/IndexTable';

export default Vue.extend({

  components: { IndexTable },
  props: {
    organization: {
      type: Object,
      required: false,
      default: () => undefined,
    },

    filters: {
      type: Array,
      required: true,
    },

    fields: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    query: null,
  }),

  fetch() {
    const query = new Alert();

    if (this.organization?.id) {
      query.where('organization_id', this.organization.id);
    }

    this.query = () => query.include('organization');
  },

  computed: {
    showTagline() {
      return this.$auth.me.user.type === UserTypeEnum.EMPLOYEE;
    },

    canCreate() {
      return this.$ability.can('create', 'Alert');
    },
  },
});
</script>
<style lang="scss" scoped>
.heading {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}
</style>
